const columns = [
  {
    label: "创建日期",
    prop: "order_create_time",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: "left",
  },
  {
    label: "达人昵称(英文)",
    prop: "creator_handle",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "达人昵称(原文)",
    prop: "creator_name",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "订单号",
    prop: "order_id",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
  {
    label: "子订单号",
    prop: "sub_id",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
  {
    label: "商品名称",
    prop: "commodity_name",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "SKU",
    prop: "sku",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "商品ID",
    prop: "commodity_id",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
  {
    label: "店铺名称",
    prop: "store_name",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "店铺代码",
    prop: "store_code",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
  {
    label: "活动价格(฿)",
    prop: "commodity_price",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "数量",
    prop: "order_number",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
  {
    label: "订单状态",
    prop: "order_status",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "付款状态",
    prop: "payment_status",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "内容形式",
    prop: "content_type",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "联盟合作伙伴佣金率(%)",
    prop: "tap_commission_rate",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "创作者佣金率(%)",
    prop: "creator_commission_rate",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "预估计佣金额(฿)",
    prop: "estimated_commission_amount",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "预计联盟合作伙伴获得佣金(฿)",
    prop: "tap_estimated_commission_amount",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "预计创作者获得佣金(฿)",
    prop: "creator_estimated_commission_amount",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "实际计佣金额(฿)",
    prop: "actual_commission_amount",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "联盟合作伙伴获得的实际佣金(฿)",
    prop: "tap_actual_commission_amount",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "创作者获得实际佣金(฿)",
    prop: "creator_actual_commission_amount",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "已退货的商品数量",
    prop: "returned_number",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
  {
    label: "已退款的商品数量",
    prop: "refund_number",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
  {
    label: "收货时间",
    prop: "order_delivery_time",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
  {
    label: "佣金结算时间",
    prop: "commission_settlement_time",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
  {
    label: "打款单号",
    prop: "payment_order_number",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
  {
    label: "支付方式",
    prop: "payment_method",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "收款账号",
    prop: "payment_account",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
];
export default columns;
